<template>

  <!-- Start Page Title Area -->
  <div class="page-title-area">
    <div class="container">

      <div class="page-title-content" v-if="!sectionCatItem">
        <ul>
          <li class="negativeText">
            <i class="fal fa-university"></i>
            Loading ...
          </li>

        </ul>
        <h2>Training details</h2>
        <PulseLoaderBar/>
      </div>


      <div class="page-title-content" v-if="sectionCatItem">
        <ul>
          <li class="negativeText">
            <i class="fal fa-university"></i>
            training for
          </li>

        </ul>
        <h2>{{sectionCatItem.title}}</h2>
        <div class="detailsHeadButtonSection">
          <UserOpenSectionCat :subject="this.sectionCatItem" @updateUserOwnsSectioncat="user_owns_sectioncat = $event" v-if="userSignedIn"/>

          <button @click="navigateToLogin" class="default-btn" v-if="!userSignedIn">
            Sign in for training
          </button>
          <span v-if="!userSignedIn && !user_owns_sectioncat && productsAvailable">&nbsp;&nbsp;</span>
          <button @click="scrollToPricing" v-if="!user_owns_sectioncat && productsAvailable" class="default-btn altbgcolor2">Show price options</button>
        </div>
      </div>

    </div>
  </div>
  <!-- End Page Title Area -->


  <SaleBanner v-if="!userSignedIn && !user_owns_sectioncat"/>


  <!-- modern content start -->

  <section class="sectionCatContentArea" v-if="sectionCatItem">


    <!-- sectionCatDetail pair start -->
    <section class="sectionCatContentPair">

      <div class="sectionCatDetailTextArea">
        <div class="sectionCatDetailTextAreaTitle">
          <h3>{{sectionCatItem.desc}}</h3>
        </div>

        <div class="sectionCatDetailTextAreaDescription">
          {{sectionCatItem.desclong}}
        </div>
      </div>

      <div class="sectionCatDetailImageArea">
        <div class="sectioncat-img" :class="sectionCatUnderscore"><img/></div>
      </div>

    </section>
    <!-- sectionCatDetail pair end -->



    <!-- sectionCatDetail pair start -->
    <section class="sectionCatContentPair">

      <div class="sectionCatDetailImageArea">
        <div class="sectioncat-img-onsmartphone"><img/></div>
      </div>

      <div class="sectionCatDetailTextArea">
        <h3>User experience</h3>
        <ul>
          <li>Works on mobile device</li>
          <li>Many detailed explanations</li>
          <li>Pause and continue a training session any time</li>
          <li>No timer, no pressure. Focus on learning.</li>
          <li>Constant worldwide performance by autoscaling</li>
        </ul>
      </div>

    </section>
    <!-- sectionCatDetail pair end -->


    <!-- sectionCatDetail pair start -->
    <section class="sectionCatContentPair">


      <div class="sectionCatDetailTextArea">
        <h3>Targeted learning</h3>
        <ul>
          <li>Skill strength analyzing</li>
          <li>Shows your skill progress of a section</li>
          <li>Finds your knowledge gaps by section strength analysis</li>
        </ul>

        <div>
          Do not waste time by learning what you already know. Focus on your knowledge gaps.
          Our statistics analyses your strength by sections and skill level. Remembering your gaps, we will provide the right questions to you.
        </div>
      </div>


      <div class="sectionCatDetailImageArea">
        <div class="sectioncat-img-skills"><img/></div>
      </div>


    </section>
    <!-- sectionCatDetail pair end -->



    <!-- sectionCatDetail pair start -->
    <section class="sectionCatContentPair">

      <div class="sectionCatDetailImageArea">
        <div class="sectioncat-img-syntax"><img/></div>
      </div>


      <div class="sectionCatDetailTextArea">
        <h3>Syntax highlighting</h3>
        <div>

          Learning is more effective with information that's easy to read and understand; we display commands in a familiar way, provide explanations, and include links for in-depth topic exploration to help you prepare for exams.
        </div>
      </div>




    </section>
    <!-- sectionCatDetail pair end -->



  </section >

  <!-- modern content end -->

  <!-- Start Our Challenges Area -->
  <section class="challenges-area challenges-area-two pt-100" v-if="sectionCatItem">
    <div class="container">
      <div class="section-title2 white-title">
        <span>training content</span>
        <h2>covered sections of this training</h2>
      </div>

      <div class="row challenges-items">

        <div class="col-lg-20percent col-sm-6" v-for="section in sectionCatItem.sections.sort((a, b) => a.sort - b.sort)" v-bind:key="section.id">
          <div class="single-challenges overly-one">
            <div class="overly-two">
              <i class="fal fa-check-circle"></i>
              <h3>{{section.title}}</h3>
              <p>{{section.desc}}</p>

            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="challenges-shape">
      <img src="../assets/img/challenges-shape.png" alt="Image">
    </div>
  </section>
  <!-- End Our Challenges Area -->


  <!-- Start Pricing Area -->
  <section class="pricing-area pt-50" id="pricing-area" v-if="sectionCatItem && sectionCatItem.products !== undefined && productsAvailable">
    <div class="container">
      <div class="section-title3">
        <span>join now and become prepared!</span>
        <h2>Select the plan that suits you best</h2>
      </div>


      <div id="app" class="typeOfPricingSelection">
        <p :class="{ selected: typeOfPricing === 'subscription' }" @click="setTypeOfPricing('subscription')">Subscription</p>
        <p :class="{ selected: typeOfPricing === 'onetimepayment' }" @click="setTypeOfPricing('onetimepayment')">One-time payment</p>
      </div>

      <div id="app" class="typeOfPricingSelection-currency">
        <p :class="{ selected: currency === 'EUR' }" @click="setCurrency('EUR')">EUR</p>
        <p :class="{ selected: currency === 'USD' }" @click="setCurrency('USD')">USD</p>
      </div>


      <div class="row">
        <ProductBox  v-for="product in filteredProducts" v-bind:key="product.id" v-bind:product="product" v-bind:sectionCatItem="sectionCatItem"/>
      </div>

    </div>
  </section>
  <!-- End Pricing Area -->

  <div>&nbsp;</div>


</template>




<script>
import axios from 'axios';
import UserOpenSectionCat from '@/views/fragments/UserOpenSectionCat.vue'
import ProductBox from '@/views/content/productbox'
import PulseLoaderBar from "@/views/fragments/PulseLoaderBar.vue";
import SaleBanner from '@/views/content/splash/saleBanner.vue';

export default {
  inject: ['sectionCat'],

  components: {
    PulseLoaderBar,
    UserOpenSectionCat,
    ProductBox,
    SaleBanner
  },

  data() {


    return {
      sectionCatItem: null,
      typeOfPricing: 'subscription',
      currency: 'USD',
      user_owns_sectioncat: false
    }
  },


  created() {
    window.scrollTo(0,0); // scroll to top

    this.sectionCat = this.$route.params.sectionCat
    console.log("Section cat opened: %s", this.sectionCat)
    const url = process.env.VUE_APP_URL_GET_SUBJECTDETAILS+'/'+this.sectionCat
    console.log("GET: "+url)
    axios.get(url, { cache: true }).then(
        res=> {
          this.sectionCatItem=res.data.payload.item;
          window.scrollTo(0,0); // scroll to top
        }
    ).catch(error => console.log(error));

  },

  computed: {
    sectionCatUnderscore(){
      return this.sectionCatItem.sectionCat.replace("/","_")
    },
    userSignedIn(){
      return this.$store.getters.userSignedIn
    },
    productsAvailable(){
      var productsAvailable = false
      if (this.sectionCatItem !== null && this.sectionCatItem.products.length > 0){
        productsAvailable = true
      }
      return productsAvailable
    },
    filteredProducts() {
      // Filters sectionCatItem.products based on typeOfPricing
      return this.sectionCatItem.products.filter(
          product => product.type === this.typeOfPricing && product.currency === this.currency
      );
    }
  },


  methods: {
    setCurrencyBasedOnLocale() {
      const eurLocales = ['de-AT', 'fr-BE', 'nl-BE', 'el-CY', 'et-EE', 'fi-FI', 'fr-FR', 'de-DE', 'el-GR', 'en-IE', 'it-IT', 'lv-LV', 'lt-LT', 'fr-LU', 'de-LU', 'en-MT', 'mt-MT', 'nl-NL', 'pt-PT', 'sk-SK', 'sl-SI', 'es-ES'];
      const eurLanguages = ['de', 'fr', 'nl', 'el', 'et', 'fi', 'it', 'lv', 'lt', 'mt', 'pt', 'sk', 'sl', 'es'];
      // 'en' is excluded as maybe used for USA. sorry Ireland and Malta!

      // Logging navigator.language and navigator.languages
      const primaryLanguage = navigator.language;
      const firstPreferredLanguage = navigator.languages ? navigator.languages[0] : 'undefined';
      console.log("navigator.language:", primaryLanguage);
      console.log("navigator.languages[0]:", firstPreferredLanguage);

      // Determine the browser's locale using the navigator object
      const browserLocale = primaryLanguage || firstPreferredLanguage;
      console.log("Using browser locale:", browserLocale);

      // Check if the full locale or just the language part is in the EUR list
      if (eurLocales.includes(browserLocale)) {
        console.log("Locale match found in EUR locales:", browserLocale);
        this.currency = 'EUR';
      } else if (eurLanguages.includes(browserLocale.split('-')[0])) {
        console.log("Language match found in EUR languages:", browserLocale.split('-')[0]);
        this.currency = 'EUR';
      } else {
        console.log("No matches found. Default currency (USD) will be used.");
      }
    },
    setTypeOfPricing(value) {
      this.typeOfPricing = value;
    },
    setCurrency(value){
      this.currency = value;
    },
    scrollToPricing() {
      const element = document.getElementById('pricing-area');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    },
    navigateToLogin() {
      this.$router.push('/login');
    },
    loadTrustedBadge() {
      this.removeElementsByClass('ds24b-bottom');
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://www.digistore24.com/trusted-badge/32072/XhbKn0IySgg3a8n/salespage/';
      document.body.appendChild(script);
    },
    removeElementsByClass(className) {
      const elements = document.getElementsByClassName(className);
      while (elements.length > 0) {
        elements[0].parentNode.removeChild(elements[0]);
      }
    }
  },

  mounted() {
    this.loadTrustedBadge();
    this.setCurrencyBasedOnLocale();
  }



  }

</script>
